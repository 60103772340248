import React, { CSSProperties } from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import { TooltipTriggerProps } from 'react-popper-tooltip/dist/types';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { StyledTooltip, StyledTrigger } from './styles';

const Tooltip = props => {
  const { showToolTip, renderToolTipBody, tooltipRef, getTooltipProps } = props;

  if (!showToolTip) {
    return (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}
      />
    );
  }

  return (
    <StyledTooltip>
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}
      >
        {renderToolTipBody()}
      </div>
    </StyledTooltip>
  );
};

interface AppTooltipProps extends Partial<TooltipTriggerProps> {
  renderTriggerButton: () => JSX.Element;
  renderToolTipBody: () => JSX.Element;
  showToolTip: boolean;
  triggerStyle?: CSSProperties;
}

const AppTooltip = (props: AppTooltipProps) => {
  const {
    renderTriggerButton,
    renderToolTipBody,
    showToolTip,
    triggerStyle,
    ...passProps
  } = props;

  // Selectors
  const isMobile = useIsMobile();

  return (
    <TooltipTrigger
      closeOnOutOfBoundaries={false}
      trigger={isMobile ? 'click' : 'hover'}
      tooltip={innerProps => (
        <Tooltip
          renderToolTipBody={renderToolTipBody}
          showToolTip={showToolTip}
          {...innerProps}
        />
      )}
      modifiers={{
        offset: { offset: '0, 10' },
      }}
      {...passProps}
    >
      {({ getTriggerProps, triggerRef }) => (
        <StyledTrigger style={triggerStyle}>
          <span
            {...getTriggerProps({
              ref: triggerRef,
              className: 'trigger',
            })}
            style={{ display: 'flex' }}
          >
            {renderTriggerButton()}
          </span>
        </StyledTrigger>
      )}
    </TooltipTrigger>
  );
};

export default AppTooltip;
