import styled from 'styled-components';

export const StyledTooltip = styled.div`
  .tooltip-container {
    background: hsla(0, 0%, 0%, 0.75);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5em 1em;
    font-size: 12px;
    max-width: 250px;
    z-index: 500;
  }
`;

export const StyledTrigger = styled.div`
  vertical-align: middle;
`;
